﻿import $ from "jquery";
import { fileIsWorkDocument } from "./typescript/Vgr.Util";

if (!String.prototype.startsWith) {
  String.prototype.startsWith = function (searchString, position) {
    position = position || 0;
    return this.indexOf(searchString, position) === position;
  };
}
//<!-- Piwik -->
var urlRightPart = location.pathname;

var isCms = false;
// Exclude urls under /episerver/, /sec/ and all that begins with ?authToken= and all under gemensamma sidor from piwik
if (urlRightPart)
  isCms =
    urlRightPart !== "" &&
    (urlRightPart.toLowerCase().startsWith("/episerver/") ||
      urlRightPart.toLowerCase().startsWith("/sec/") ||
      urlRightPart.startsWith("?authToken=") ||
      urlRightPart.indexOf("delade-menyer") > -1);

if (document.getElementById("piwikSiteId") && !isCms) {
  //BUG: IE10 does not support the elem.dataset attribute. Changing this to a more backwards-compatible code -- Mathias
  //var siteId = document.getElementById("piwikSiteId").dataset.piwiksiteid;
  var siteId = document
    .getElementById("piwikSiteId")
    .getAttribute("data-piwiksiteid");
  if (siteId !== "") {
    var _paq = (window._paq = window._paq || []);

    if (document.getElementById("piwikSiteDomain")) {
      var domain = document
        .getElementById("piwikSiteDomain")
        .getAttribute("data-piwiksitedomain");
      if (domain !== "") {
        _paq.push([
          "setDomains",
          [
            document
              .getElementById("piwikSiteDomain")
              .getAttribute("data-piwiksitedomain"),
          ],
        ]);
      }
    }

    var errorPage = document.getElementById("errorpage");

    //This is done for the 404-page.
    if (
        errorPage !== undefined &&
        errorPage !== null &&
        errorPage.hasAttribute("data-error-page") &&
        errorPage.getAttribute("data-error-page") === "404"
    ) {
      _paq.push([
        "setDocumentTitle",
        "404 Error/URL = " +
          encodeURIComponent(
            document.location.pathname + document.location.search
          ) +
          "/From = " +
          encodeURIComponent(document.referrer),
      ]);
      _paq.push([
        "trackEvent",
        "Error Pages",
        "404 Error",
        "Page: " +
          document.location.pathname +
          document.location.search +
          "/From = " +
          document.referrer,
      ]);
    }
    var linkurl = location.href;

    if (linkurl.indexOf("?authToken=") > -1) {
      linkurl = linkurl.substring(0, linkurl.indexOf("?authToken=")); // Ta bort ?authToken= från url:en innan den skickas till matomo
    }

    var lastpart = linkurl.substr(-1);
    var lowercaseurl = linkurl.toLowerCase(); // Skicka alltid url:en till Matomo med små bokstäver för att url:er med små/stora bokstäver inte ska loggas som flera olika sidor.

    if (lastpart !== "/") lowercaseurl += "/"; // Se till att url:en alltid slutar med ett snedstreck.

    _paq.push(["setCustomUrl", lowercaseurl]);
    _paq.push(["trackPageView"]);

    $("a[href^='tel:']").on("click", function (e) {
      _paq.push(["trackEvent", "Links", "Clicked phonelink", this.href]);
    });

    //$("a[href^='mailto:']").on("click", function (e) {
    //    _paq.push(['trackEvent', 'contactform', 'Clicked maillink', this.href + "-"]);
    //});

    // If this is hitta.vgregion.se and this isn't the start page and the url contains a search query
    if (
      location.host === "hitta.vgregion.se" &&
      location.pathname !== "/" &&
      location.pathname !== "" &&
      window.location.href.indexOf("q") > -1
    ) {
      // For some reason only searches (not page views) are logged, so long a search query exists on Hitta's search pages.
      // As a workaround we track the page view again but without the search query
      _paq.push([
        "setCustomUrl",
        location.protocol +
          "//" +
          location.host +
          location.pathname.toLowerCase(),
      ]); // This will return the URL without any parameters
      _paq.push(["trackPageView"]);
    }

    _paq.push(["enableLinkTracking"]);

    trackDocumentDownloads();

    (function () {
      var u = "https://piwik.vgregion.se/";

      // Piwik has two servers, one for internal sites tracking and one for external sites tracking.
      // This sets whether we should track to the internal or the external.
      var usePiwikExternal = "False";
      if (document.getElementById("usePiwikExternal")) {
        usePiwikExternal = document
          .getElementById("usePiwikExternal")
          .getAttribute("data-usePiwikExternal");
      }

      // Script to activate Matomo Tag Manager
      if (document.getElementById("tagmanagerId") != null) {
        var useTagManager = document
          .getElementById("useTagManager")
          .getAttribute("data-useTagManager");

        var tagmanagerId = document
          .getElementById("tagmanagerId")
          .getAttribute("data-tagmanagerId");

        if (
          tagmanagerId != null &&
          tagmanagerId != undefined &&
          tagmanagerId !== ""
        ) {
          if (tagmanagerId.indexOf("container_") == -1) {
            tagmanagerId = "container_" + tagmanagerId;
          }

          if (tagmanagerId.indexOf(".js") == -1) {
            tagmanagerId = tagmanagerId + ".js";
          }
          if (useTagManager === "True") {
            initTagManager(usePiwikExternal, useTagManager, tagmanagerId);
          }
        }
      }
      if (usePiwikExternal === "True") {
        u = "//piwik-ext.vgregion.se/";
      }

      if (document.getElementById("piwikAdditionalSiteId")) {
        var additionalSiteId = document
          .getElementById("piwikAdditionalSiteId")
          .getAttribute("data-piwiksiteid");
        if (additionalSiteId !== "") {
          _paq.push(["addTracker", u + "/piwik.php", additionalSiteId]); // adds an additional tracker, to track the same data into a second Piwik website
        }
      }

      _paq.push(["setTrackerUrl", u + "piwik.php"]);
      _paq.push(["setSiteId", siteId]);
      var d = document,
        g = d.createElement("script"),
        s = d.getElementsByTagName("script")[0];
      g.type = "text/javascript";
      g.async = true;
      g.defer = true;
      g.src = u.toLowerCase() + "piwik.js";
      s.parentNode.insertBefore(g, s);
    })();
  }
}
//<!-- End Piwik Code -->

// Since not all links to Alfreco documents has the file format in the url, we need to manually trigger a click as a "download" to Piwik
function trackDocumentDownloads() {
  // Iterate all links
  $("main a").each(function () {
    var aTag = $(this)[0];

    try {
      // Check if this is an link to Alfresco
      if (aTag.host === "alfresco.vgregion.se") {
        // Is the file format missing?
        if (!fileIsWorkDocument(aTag.pathname)) {
          // Adda a click event that will track this as a download to Piwik
          $(this).on("click", function () {
            _paq.push(["trackLink", aTag.href, "download"]);
          });
        }
      }
    } catch (e) {}
  });
}

function initTagManager(usePiwikExternal, useTagManager, tagmanagerId) {
  var src = "https://piwik.vgregion.se/js/" + tagmanagerId + "";

  if (usePiwikExternal === "True") {
    src = "https://piwik-ext.vgregion.se/js/" + tagmanagerId + "";
  }

  var _mtm = _mtm || [];
  _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
  var d = document,
    g = d.createElement("script"),
    s = d.getElementsByTagName("script")[0];
  g.type = "text/javascript";
  g.async = true;
  g.defer = true;
  g.src = src;
  s.parentNode.insertBefore(g, s);
}
